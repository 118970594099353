import Splide from '@splidejs/splide';
import '@splidejs/splide/css';
import {AutoScroll} from '@splidejs/splide-extension-auto-scroll';
import {Intersection} from '@splidejs/splide-extension-intersection';

function init() {
  const els = [...document.querySelectorAll('.splide')];

  if (els.length) {
    Splide.defaults = {
      type: 'loop',
      autoWidth: true,
      perPage: 1,
      focus: 'center',
      gap: 'var(--space-20-40)',
      lazyLoad: 'nearby',
      autoScroll: {
        speed: 0.5,
      },
    };

    for (var i = 0; i < els.length; i++) {
      const splide = new Splide(els[i]);

      splide.mount({AutoScroll, Intersection});
    }
  }
}

export default init;

init();
